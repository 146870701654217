import {
  Auth0Client,
  Auth0ClientOptions,
  PopupTimeoutError,
  PopupCancelledError,
  type User,
  type IdToken,
} from '@auth0/auth0-spa-js'; // https://github.com/auth0/auth0-spa-js

export type Auth0User = User;
export type Auth0IdToken = IdToken;

export const loginWithPopupErrors = {
  PopupTimeoutError,
  PopupCancelledError,
};

type Auth0Scope =
  | 'openid'
  | 'profile'
  | 'email'
  | 'read:user'
  | 'write:user'
  | 'offline_access';
const scopes: Auth0Scope[] = [
  'openid',
  'profile',
  'email',
  'read:user',
  'write:user',
  'offline_access',
];

const { AUTH_AUDIENCE = 'https://db.knapsack.cloud/v1/graphql' } = process.env;

const config: Auth0ClientOptions = {
  domain: 'auth.knapsack.cloud',
  clientId: 'mESOJI3993dm8JCPRuxddyR6X4okwk6w',
  authorizationParams: {
    audience: AUTH_AUDIENCE,
    scope: scopes.join(' '),
  },
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true, // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
  cookieDomain: '.knapsack.cloud',
};

export const auth0 = new Auth0Client(config);
