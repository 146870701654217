import pkg from '../../package.json';

export const appClientApiPath = '/api/v1';

// DO NOT INCLUDE TRAILING SLASH!
export const SECTION_PATH_BASES = {
  DESIGN: '/design',
  DEVELOP: '/develop',
  SETTINGS: '/settings',
  PROTO: '/prototypes',
} as const;

/**
 * Base Paths for URLs
 * DO NOT INCLUDE TRAILING SLASH!
 */
export const BASE_PATHS = {
  PATTERN: '/pattern',
  PATTERN_EMBED: '/pattern-embed',
  PAGES: '/pages',
  PLUGINS: '/plugins',
  TOKENS: `${SECTION_PATH_BASES.DESIGN}/design-tokens`,
  // Note how this slightly differs from above: 'token' is plural vs singular.
  // This is done so routing doesn't think we're in an area we are not.
  VARIABLES: `${SECTION_PATH_BASES.DESIGN}/variables`,
  SETTINGS: `${SECTION_PATH_BASES.SETTINGS}`,
};

/** Note that this is not a secret that would be bad if users got it */
export const firebaseApiKey = 'AIzaSyBVfGRjNrDIJzn8_ZEGodVA8WTVQXOTxUI';

// Magic string that determines topmost nav items
export const secondaryNavRootKey = 'root';

/**
 * The `version` from `@knapsack/app-ui/package.json`
 * @see {appUiMajorVersion}
 */
export const appUiVersion = pkg.version;
if (typeof appUiVersion !== 'string') {
  throw new Error(
    `Expected appUiVersion to be a string but got "${appUiVersion}"`,
  );
}

/**
 * @see {appUiVersion} for full version
 */
export const appUiMajorVersion = parseInt(appUiVersion.split('.')[0], 10);

/** Version of `@knapsack/app` package installed on their running App Client that we desire. If it's older, then we show a warning.  */
export const appClientMinimumVersion = {
  desired: '4.28.0',
  // just an idea - not implemented
  // required: '^3.20.0',
};

/**
 * This is a function, not a boolean!!
 * Do `if (isCypress())` not `if (isCypress)`
 */
export const isCypress = () =>
  typeof window !== 'undefined' && !!(window as any).Cypress;

/** Set during Ava runs */
export const isUnitTesting = process.env.NODE_ENV === 'test';
export const DEFAULT_FAVICON_URL =
  'https://cdn.knapsack.cloud/assets/favicon.ico';
export const HOMEPAGE_PAGE_ID = 'homepage';

/**
 * Query param added to the URL when logging out from Auth0
 * Value is root relative path to redirect to after successful login
 */
export type LogoutRedirectQueryParam = 'ks-logout-redirect';
